import React, {useRef, useEffect} from 'react'
import Card from '../property-card'
import { graphql, useStaticQuery } from 'gatsby';
import OwlCarousel from 'react-owl-carousel3';

//Redux
import { connect } from 'react-redux'

//Dispatch
import {getFeaturedPropertiesAction} from '../../redux/propertiesDucks'

const options = {
    loop:true,
    smartSpeed:1000,
    stagePadding:10,
    center:true,
    margin:10,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,

        }
    },
}

const Properties = ({properties,api_key,dispatch}) => {

    const slider = useRef()
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                    home {
                        properties{
                            title
                        }
                    }
                }
            } 
        }`)

    useEffect(() => {
        if(api_key){
            dispatch(getFeaturedPropertiesAction())
        }
    }, [api_key])

    return properties?.data?.objects?.length > 0 ? (
        <section id="featured-props">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h2 class="section-title pb-5">{realEstate.sections.home.properties.title}</h2>
                    </div>
                </div>
            </div>
            <div className="props d-flex align-items-center justify-content-center my-4">
                <OwlCarousel ref={slider}
                    {...options}
                    className="overflow-hidden">
                    {properties.data.objects.map((prop,index) => (
                        <Card key={index} basic={true} property={prop}/>
                    ))}
                </OwlCarousel>
                <div className="arrows">
                    <div className="arrow" onClick={() => slider.current.prev()}> {'<'} </div>
                    <div className="arrow" onClick={() => slider.current.next()}> {'>'} </div>
                </div>
            </div>
        </section>
    ):''
}

export default connect(state => ({
    properties: state.properties.featured,
    api_key:state.settings.keys.tokko
}),null)(Properties);