// import { navigate } from '@reach/router'
import React from 'react'
import { connect } from 'react-redux'
import {getTotalListFilters, clearPropertiesAction} from '../redux/propertiesDucks'
import { useRef } from "react";
import { useState, useEffect } from "react";
import Searcher from "./autocomplete";
// import { useNavigate } from '@reach/router'
import { navigate } from "gatsby"

const Search = ({totalListFilters,dispatch}) => {


    // const navigate = useNavigate();
    const refOperation = useRef();
    const refType = useRef();
    const [locations, setLocation] = useState([]);
  
    const [router, setRouter] = useState({
      location: "",
      type: "",
      operation: "venta",
    });
  
    useEffect(() => {
      if (totalListFilters.data) {
        for (let index = 0; index < totalListFilters.data.objects.locations.length; index++) {
          if(locations.length <= 0){
            setLocation((locations) => [
              ...locations,
              totalListFilters.data.objects.locations[index].location_name,
            ]);
          }
        }
      }
    }, [totalListFilters]);

    const updateRoute = (value) => {
      setRouter((router) => ({
        ...router,
        [value.current.name]: value.current.value,
      }));
    };

    const goRoute = (event) => {
        event.preventDefault();
        // dispatch(changeLocationAction(router.operation.toLowerCase()));
        dispatch(clearPropertiesAction());
        let routerLink =(
              router.operation
              .toLowerCase()
              .replace(/ /g, "-") +
              (router.type ? "/" : '')
              + router.type
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") 
              +
              (router.location !== '' ? 
                '/' + 
                router.location
                .toLowerCase()
                .replace(/ /g, "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
              :'')
        );
        navigate(routerLink);
        // console.log(routerLink)
    };

                    

    return totalListFilters ? (
        <div class="search-home d-flex align-items-center py-3 py-lg-4">
            <div class="container-fluid">
                <form  onSubmit={goRoute} class="row no-gutters align-items-stretch px-xl-5 search-form mb-0">
                    <div class="col-6 my-2 col-lg-3 px-2 pr-lg-3">
                        <div class="content-select operation-type">
                            <select ref={refOperation} onChange={() => updateRoute(refOperation)} name="operation" id="operacion">
                                <option value="propiedades">Tipo de operación</option>
                                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <option value="Venta">Venta</option> : '' }
                                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <option value="Alquiler">Alquiler</option> : '' }
                                    {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) ? <option value="Alquiler Temporario">Alquiler Temporario</option> : ''}
                            </select>
                            <i></i>
                        </div>
                    </div>
                    <div class="col-6 my-2 col-lg-3 px-2 pr-lg-3">
                        <div class="content-select property-type">
                            <select name="type"
                                    id="type"
                                    ref={refType}
                                    onChange={() => updateRoute(refType)}>
                                <option value="all">Tipo de propiedad</option>
                                    {totalListFilters.data ? 
                                        totalListFilters.data.objects.property_types.map((item,index) => (
                                            <option value={item.type}>{item.type}</option>
                                            )
                                        )
                                        :''
                                    }
                            </select>
                            <i></i>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row no-gutters d-flex align-items-stretch">
                            <div class="col-12 my-2 col-lg-9 px-2 pr-lg-3">
                                <div class="content-select">
                                    <Searcher lang={locations} updateRoute={updateRoute} />
                                </div>  
                            </div>
                            <div class="col-12 my-2 px-2 px-lg-0 col-lg-3">
                                <button class="btn btn-white">Buscar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div> 
    ):''
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
}),null)(Search);
