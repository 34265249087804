import { Link } from "gatsby"
import React,{useRef, useEffect, useState} from "react"
import { graphql, useStaticQuery } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';


const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                    }
                }
    }`)

    return(
        <section class="about-us py-5 mt-5" style={{backgroundImage:"url(" + realEstate.sections.home.about.image + ""}}>
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="section-name">{realEstate.sections.home.about.title}</p>
                    </div>
                    <div class="col-lg-10">
                        <h2>{realEstate.sections.home.about.paragraph}</h2>
                        {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br class="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
                    </div>
                    <div class="col-10 mt-lg-5 offset-lg-2">
                        <Link to={realEstate.sections.home.about.buttons[0].link} class="btn btn-red">{realEstate.sections.home.about.buttons[0].value}</Link>
                    </div>
                    <div class="col-lg-10 offset-lg-2 pt-5 mt-5">
                        <div class="row py-lg-5">
                            <div class="col-lg-5 pr-lg-4">
                                <div class="item">
                                    <div class="row no-gutters">
                                        <div class="col-6 col-lg-6 text-end">
                                            <h1 class="hyper d-flex justify-content-end">+
                                                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                    {({ isVisible }) => (
                                                    <span class="counter-value one">
                                                        {isVisible ? <CountUp end={realEstate.sections.home.about.items[0].value} /> : null}
                                                    </span>
                                                    )}
                                                </VisibilitySensor>
                                            </h1>
                                        </div>
                                        <div class="col-6 col-lg-6 px-3">
                                            <p>{realEstate.sections.home.about.items[0].paragraph}</p>
                                            {/* <p>AÑOS DE EXPERIENCIA <br class="d-none d-lg-block" />EN EL MERCADO <br class="d-none d-lg-block" />INMOBILIARIO.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="item" id="counter">
                                    <div class="row no-gutters">
                                        <div class="col-7 text-end">
                                            <h1 class="hyper d-flex justify-content-end">+
                                                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                    {({ isVisible }) => (
                                                    <span class="counter-value one">
                                                        {isVisible ? <CountUp end={realEstate.sections.home.about.items[1].value} /> : null}
                                                    </span>
                                                    )}
                                                </VisibilitySensor>
                                            </h1>
                                        </div>
                                        <div class="col-5 px-3">
                                            <p>{realEstate.sections.home.about.items[1].paragraph}</p>
                                            {/* <p>OPERACIONES INMOBILIARIAS <br class="d-none d-lg-block" />REALIZADAS.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About